import { random } from "@/utils/helpers";
import { REQUEST_CODES } from "@/constants/const-http";
import { defineNuxtPlugin } from "@nuxtjs/composition-api";
import { consola } from 'consola';

export default defineNuxtPlugin(async function auth({ $auth, $axios, redirect, ...rest }) {
    consola.info('auth plugin', $auth.strategy.token.get())
    $axios.onRequest(async config => {
        let token = $auth.strategy.token.get();
        if (token) {
            await $axios.setHeader('token', token)
        }

        const userId = $auth.user ? $auth.user.id : 0
        const seqnum = `SCBWWW${userId}${Date.now()}${random(6)}`;

        await $axios.setHeader('seqnum', seqnum)
        return config
    })
    $axios.onResponse(async res => {
        if (res && res.code == REQUEST_CODES.NO_LOGIN) {
            console.log('auth onResponse', res.code)
            // if(){}
            await redirect("/")
            if (process.env.client) {
                console.log('process.env.client')
                $auth.reset();
            }
            // location.href = '/'
        }

        return res;
    })
    $axios.onError((error) => {
        // redirect("/")
        console.error(error)
    })


    if($auth.loggedIn && $auth.user){
        // console.log($auth)
    }

})